<template>
    <div class="app-container">
        <el-form ref="form" :model="activityInfoDo" :rules="rules" label-width="120px">
            <div class="content-title">活动说明</div>
            <div class="con-body">
                <el-form-item label="活动名称" prop="activityName">
                    <el-input v-model="activityInfoDo.activityName"></el-input>
                </el-form-item>
                <el-form-item label="活动类型" prop="policyNo">
                    <el-select v-model="activityInfoDo.policyNo" placeholder="请选择">
                        <el-option v-for="item in policyList" :key="item.id" :label="item.policyName"
                            :value="item.policyNo"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="活动说明">
                    <el-input type="textarea" v-model="activityInfoDo.activityRemark"></el-input>
                </el-form-item>
            </div>
            <div class="content-title">活动内容</div>
            <div class="con-body">
                <el-form-item label="资金流转">
                    <span>资金从一级机构开始下发扣8%税点</span>
                </el-form-item>
                <el-form-item label="商户激活">
                    <div>
                        <el-form-item prop="activeType">
                            <el-select v-model="activityInfoDo.activeType" placeholder="请选择">
                                <el-option label="交易满额" value="trans_target"></el-option>
                                <el-option label="交易冻结" value="trans_freeze"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div style="padding:10px 0" v-if="activityInfoDo.activeType == 'trans_target'">
                        商户审核通过后
                        <el-form-item prop="activeRewardDays" class="form-inline">
                            <span class="activeInp"><el-input v-numberInt:0 v-model="activityInfoDo.activeRewardDays"
                                    key="1"></el-input></span>
                        </el-form-item>
                        天内交易≥
                        <el-form-item prop="activeTargetAmount" class="form-inline">
                            <span class="activeInp2"><el-input v-numberInt v-model="activityInfoDo.activeTargetAmount"
                                    key="2"></el-input></span>
                        </el-form-item>
                        元，奖励
                        <el-form-item prop="activeRewardAmount" class="form-inline">
                            <span class="activeInp2"><el-input v-numberInt v-model="activityInfoDo.activeRewardAmount"
                                    key="3"></el-input></span>元
                        </el-form-item>
                    </div>
                    <div style="padding:10px 0" v-if="activityInfoDo.activeType == 'trans_freeze'">
                        冻结满
                        <el-form-item prop="activeTargetAmount" class="form-inline">
                            <span class="activeInp2"><el-input v-numberInt v-model="activityInfoDo.activeTargetAmount"
                                    key="4"></el-input></span>
                        </el-form-item>
                        元，奖励
                        <el-form-item prop="activeRewardAmount" class="form-inline">
                            <span class="activeInp2"><el-input v-numberInt v-model="activityInfoDo.activeRewardAmount"
                                    key="5"></el-input></span>元
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="商户重复激活">
                    <div>
                        <el-form-item prop="activeRepeatStatus" class="form-inline">
                            <el-select v-model="activityInfoDo.activeRepeatStatus" placeholder="请选择">
                                <el-option label="是" :value="1"></el-option>
                                <el-option label="否" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <span v-if="activityInfoDo.activeRepeatStatus == '1'">
                            奖励 <el-form-item prop="activeRepeatRewardAmount" class="form-inline">
                                <span class="activeInp2"><el-input v-numberInt
                                        v-model="activityInfoDo.activeRepeatRewardAmount"></el-input></span></el-form-item>元
                        </span>
                    </div>
                </el-form-item>
                <el-form-item label="逾期未激活">
                    <div>
                        机具入库后
                        <el-form-item prop="expireReduceDays" class="form-inline">
                            <span class="activeInp"><el-input v-numberInt:0
                                    v-model="activityInfoDo.expireReduceDays"></el-input></span>
                        </el-form-item>
                        天内未激活，扣除
                        <el-form-item prop="expireReduceAmount" class="form-inline">
                            <span class="activeInp2"><el-input v-numberInt
                                    v-model="activityInfoDo.expireReduceAmount"></el-input></span>
                        </el-form-item>
                        元
                    </div>
                </el-form-item>
                <el-form-item label="绑定未激活">
                    <div>
                        商户绑定后
                        <el-form-item prop="bindReduceDays" class="form-inline">
                            <span class="activeInp"><el-input v-numberInt:0
                                    v-model="activityInfoDo.bindReduceDays"></el-input></span>
                        </el-form-item>
                        天内未激活，扣除
                        <el-form-item prop="bindReduceAmount" class="form-inline">
                            <span class="activeInp2"><el-input v-numberInt:3
                                    v-model="activityInfoDo.bindReduceAmount"></el-input></span>
                        </el-form-item>
                        元
                    </div>
                </el-form-item>
                <el-form-item prop="invalidStatus" label="失效激活">
                    <el-select v-model="activityInfoDo.invalidStatus" placeholder="请选择">
                        <el-option label="否" :value="0"></el-option>
                        <el-option label="是" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=" " v-if="activityInfoDo.invalidStatus == '1'">
                    机具入库超出{{ invalidRewardDays }}（激活天）天后，再激活，奖励<el-form-item prop="invalidRewardAmount"
                        class="form-inline"><span class="activeInp2"><el-input v-numberInt
                                v-model="activityInfoDo.invalidRewardAmount"></el-input></span>元</el-form-item>
                </el-form-item>
                <el-form-item label="伪激活">针对已激活商户,
                    <el-form-item prop="fakeType" class="form-inline">
                        <el-select v-model="activityInfoDo.fakeType" placeholder="请选择">
                            <el-option label="商户激活" :value="1"></el-option>
                            <el-option label="商户开通" :value="2"></el-option>
                        </el-select>后
                    </el-form-item>
                    <el-form-item prop="fakeReduceDays" class="form-inline"><span class="activeInp"><el-input v-numberInt:0
                                v-model="activityInfoDo.fakeReduceDays"></el-input></span>天内交易＜</el-form-item>
                    <el-form-item prop="fakeTargetAmount" class="form-inline"><span class="activeInp2"><el-input v-numberInt
                                v-model="activityInfoDo.fakeTargetAmount"></el-input></span>元，扣除</el-form-item>
                    <el-form-item prop="fakeReduceAmount" class="form-inline"><span class="activeInp2"><el-input v-numberInt
                                v-model="activityInfoDo.fakeReduceAmount"></el-input></span>元</el-form-item>
                </el-form-item>
                <el-form-item label="商户达标">
                    <div>
                        <el-form-item prop="standardType">
                            <el-select v-model="activityInfoDo.standardType" placeholder="请选择">
                                <el-option label="逐次达标" value="each"></el-option>
                                <el-option label="一次达标" value="once"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div v-if="activityInfoDo.standardType == 'each'">
                        <div style="padding:10px 0 5px">
                            商户审核通过后
                            <el-form-item prop="standard1StartDays" class="form-inline">
                                <span class="activeInp"><el-input v-numberInt:0 v-model="activityInfoDo.standard1StartDays"
                                        key="13"></el-input></span></el-form-item>~
                            <el-form-item prop="standard1EndDays" class="form-inline"><span class="activeInp"><el-input
                                        v-numberInt:0 v-model="activityInfoDo.standard1EndDays"
                                        key="6"></el-input></span></el-form-item>
                            天内交易≥
                            <el-form-item prop="standard1TargetAmount" class="form-inline"><span class="activeInp2"><el-input
                                        v-numberInt v-model="activityInfoDo.standard1TargetAmount"
                                        key="7"></el-input></span></el-form-item>
                            元，奖励
                            <el-form-item prop="standard1RewardAmount" class="form-inline"><span class="activeInp2"><el-input
                                        v-numberInt v-model="activityInfoDo.standard1RewardAmount"
                                        key="8"></el-input></span>元</el-form-item>
                        </div>
                        <div style="padding:10px 0 5px">
                            商户审核通过后
                            <el-form-item prop="standard2StartDays" class="form-inline"><span class="activeInp"><el-input
                                        v-numberInt:0 v-model="activityInfoDo.standard2StartDays"
                                        key="9"></el-input></span>~</el-form-item>
                            <el-form-item prop="standard2EndDays" class="form-inline"><span class="activeInp"><el-input
                                        v-numberInt:0 v-model="activityInfoDo.standard2EndDays"
                                        key="10"></el-input></span></el-form-item>
                            天内交易≥
                            <el-form-item prop="standard2TargetAmount" class="form-inline"><span class="activeInp2"><el-input
                                        v-numberInt v-model="activityInfoDo.standard2TargetAmount"
                                        key="11"></el-input></span></el-form-item>
                            元，奖励
                            <el-form-item prop="standard2RewardAmount" class="form-inline"><span class="activeInp2"><el-input
                                        v-numberInt v-model="activityInfoDo.standard2RewardAmount"
                                        key="12"></el-input></span>元</el-form-item>
                        </div>
                        <div style="padding:10px 0 5px">
                            商户审核通过后
                            <el-form-item prop="standard3StartDays" class="form-inline"><span class="activeInp"><el-input
                                        v-numberInt:0 v-model="activityInfoDo.standard3StartDays"
                                        key="13"></el-input></span>~</el-form-item>
                            <el-form-item prop="standard3EndDays" class="form-inline"><span class="activeInp"><el-input
                                        v-numberInt:0 v-model="activityInfoDo.standard3EndDays"
                                        key="14"></el-input></span></el-form-item>
                            天内交易≥
                            <el-form-item prop="standard3TargetAmount" class="form-inline"><span class="activeInp2"><el-input
                                        v-numberInt:3 v-model="activityInfoDo.standard3TargetAmount"
                                        key="15"></el-input></span></el-form-item>
                            元，奖励
                            <el-form-item prop="standard3RewardAmount" class="form-inline"><span class="activeInp2"><el-input
                                        v-numberInt:3 v-model="activityInfoDo.standard3RewardAmount"
                                        key="16"></el-input></span>元</el-form-item>
                        </div>
                    </div>
                    <div v-if="activityInfoDo.standardType == 'once'">
                        <div style="padding:10px 0 5px">
                            商户激活后
                            <el-form-item prop="standard1EndDays" class="form-inline"><span class="activeInp"><el-input
                                        v-numberInt:0
                                        v-model="activityInfoDo.standard1EndDays"></el-input></span></el-form-item>
                            天内交易≥
                            <el-form-item prop="standard1TargetAmount" class="form-inline"><span class="activeInp"><el-input
                                        v-numberInt
                                        v-model="activityInfoDo.standard1TargetAmount"></el-input></span></el-form-item>
                            元
                        </div>
                        <div style="padding:10px 0 5px">
                            商户激活后
                            <el-form-item prop="standard2EndDays" class="form-inline"><span class="activeInp"><el-input
                                        v-numberInt:0
                                        v-model="activityInfoDo.standard2EndDays"></el-input></span></el-form-item>
                            天内交易≥
                            <el-form-item prop="standard2TargetAmount" class="form-inline"><span class="activeInp"><el-input
                                        v-numberInt
                                        v-model="activityInfoDo.standard2TargetAmount"></el-input></span></el-form-item>
                            元
                        </div>
                        <div style="padding:10px 0 5px">
                            奖励
                            <el-form-item prop="standard1RewardAmount" class="form-inline"><span class="activeInp"><el-input
                                        v-numberInt
                                        v-model="activityInfoDo.standard1RewardAmount"></el-input></span></el-form-item>
                            元
                        </div>
                    </div>
                </el-form-item>
                <el-form-item prop="transCountType" label="交易统计方式">
                    <el-select v-model="activityInfoDo.transCountType" placeholder="请选择">
                        <el-option label="贷记卡刷卡交易" :value="1"></el-option>
                        <el-option label="全类型交易" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="flowStatus" label="是否配置流量卡">
                    <el-select v-model="activityInfoDo.flowStatus" placeholder="请选择">
                        <el-option label="是" :value="1"></el-option>
                        <el-option label="否" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <div v-if="activityInfoDo.flowStatus == '1'">
                    <el-tabs type="card" v-model="active">
                        <el-tab-pane label="除合利宝外的配置" name="1">
                        </el-tab-pane>
                        <el-tab-pane label="合利宝通道配置" name="2">
                        </el-tab-pane>
                    </el-tabs>
                    <div v-if="active == '1'">
                        <el-form-item label="4G流量卡1">
                            下发金额<span class="activeInp2"><el-form-item prop="flowCost" class="form-inline"><el-input
                                        v-numberInt:3 v-model="activityInfoDo.flowCost"></el-input></el-form-item></span>元
                        </el-form-item>
                        <el-form-item label="4G流量卡2">
                            下发金额<span class="activeInp2"><el-form-item prop="flowCost2" class="form-inline"><el-input
                                        v-numberInt:3 v-model="activityInfoDo.flowCost2"></el-input></el-form-item></span>元
                        </el-form-item>
                        <el-form-item label="4G流量卡3">
                            下发金额<span class="activeInp2"><el-form-item prop="flowCost3" class="form-inline"><el-input
                                        v-numberInt:3 v-model="activityInfoDo.flowCost3"></el-input></el-form-item></span>元
                        </el-form-item>
                    </div>
                    <div v-if="active == '2'">
                        <el-form-item label="收取条件" required><el-form-item prop="flowStartDays" class="form-inline">
                                商户审核通过<el-input v-numberInt:0 class="inline-input"
                                    v-model="activityInfoDo.flowStartDays"></el-input> ~ <el-input v-numberInt:0
                                    class="inline-input" v-model="activityInfoDo.flowEndDays"
                                    @blur="blurChange"></el-input>天后收取
                                <el-input v-numberInt:3 v-model="activityInfoDo.flowCost"
                                    class="inline-input" style="width: 120px;"></el-input>元</el-form-item>
                        </el-form-item>
                        <el-form-item prop="flow2StartDays" label="收取条件" required><el-form-item prop="flow2StartDays"
                                class="form-inline">
                                商户审核通过<el-input v-numberInt:0 class="inline-input" v-model="activityInfoDo.flow2StartDays"
                                    disabled></el-input> ~ <el-input v-numberInt:0 class="inline-input"
                                    v-model="activityInfoDo.flow2EndDays"></el-input>天后收取
                                <el-input v-numberInt:3 v-model="activityInfoDo.flowCost2"
                                    class="inline-input" style="width: 120px;"></el-input>元</el-form-item>
                        </el-form-item>
                    </div>
                </div>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">确定</el-button>
                </el-form-item>

            </div>
        </el-form>
    </div>
</template>
<script>
import { PolicyApi } from '@/api'
export default {
    data() {
        var checkFlowStartDays = (rule, value, callback) => {
            if (!value) {
                callback('请输入起始时间');
            }
            if (!this.activityInfoDo.flowEndDays) {
                callback('请输入结束时间');
            }
            if (value && this.activityInfoDo.flowEndDays && Number(this.activityInfoDo.flowEndDays) < Number(value)) {
                callback('请输入起始时间大于结束时间');
            }
            if (!this.activityInfoDo.flowCost) {
                callback('请输入金额');
            } else {
                callback();
            }
        };
        var checkFlow2StartDays = (rule, value, callback) => {
            if (!value) {
                callback('请输入起始时间');
            }
            if (!this.activityInfoDo.flow2EndDays) {
                callback('请输入结束时间');
            }
            if (value && this.activityInfoDo.flow2EndDays && Number(this.activityInfoDo.flow2EndDays) < Number(value)) {
                callback('请输入起始时间大于结束时间');
            }
            if (!this.activityInfoDo.flowCost2) {
                callback('请输入金额');
            } else {
                callback();
            }
        };
        return {
            activityInfoDo: { activeType: 'trans_target', activeRepeatStatus: '1', invalidStatus: '0', standardType: 'each', flowStatus: '0', transCountType: '1', fakeType: '1', flowCost: null, flowCost2: null, flowCost3: null, flowStartDays: null, flowEndDays: null, flow2StartDays: null, flow2EndDays: null },
            policyList: [],
            rules: {
                activityName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
                policyNo: [{ required: true, message: '请输入活动类型', trigger: 'blur' }],
                activeRewardDays: [{ required: true, message: '请输入天数', trigger: 'blur' }],
                activeTargetAmount: [{ required: true, message: '请输入达标金额', trigger: 'blur' }],
                activeRewardAmount: [{ required: true, message: '请输入奖励金额', trigger: 'blur' }],
                activeRepeatRewardAmount: [{ required: true, message: '请输入奖励金额', trigger: 'blur' }],
                expireReduceAmount: [{ required: true, message: '请输入扣款金额', trigger: 'blur' }],
                expireReduceDays: [{ required: true, message: '请输入天数', trigger: 'blur' }],
                fakeReduceAmount: [{ required: true, message: '请输入扣款金额', trigger: 'blur' }],
                bindReduceDays: [{ required: true, message: '请输入天数', trigger: 'blur' }],
                bindReduceAmount: [{ required: true, message: '请输入扣款金额', trigger: 'blur' }],
                fakeReduceDays: [{ required: true, message: '请输入天数', trigger: 'blur' }],
                fakeTargetAmount: [{ required: true, message: '请输入达标金额', trigger: 'blur' }],
                flowAfterDays: [{ required: true, message: '请输入天数', trigger: 'blur' }],
                flowCost: [{ required: true, message: '请输入金额', trigger: 'blur' }],
                flowCost2: [{ required: true, message: '请输入金额', trigger: 'blur' }],
                flowCost3: [{ required: true, message: '请输入金额', trigger: 'blur' }],
                flowFee: [{ required: true, message: '请输入金额', trigger: 'blur' }],
                invalidRewardAmount: [{ required: true, message: '请输入奖励金额', trigger: 'blur' }],
                invalidRewardDays: [{ required: true, message: '请输入天数', trigger: 'blur' }],
                standard1EndDays: [{ required: true, message: '请输入结束天数', trigger: 'blur' }],
                standard1RewardAmount: [{ required: true, message: '请输入奖励金额', trigger: 'blur' }],
                standard1StartDays: [{ required: true, message: '请输入起始天数', trigger: 'blur' }],
                standard1TargetAmount: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
                standard2EndDays: [{ required: true, message: '请输入结束天数', trigger: 'blur' }],
                standard2RewardAmount: [{ required: true, message: '请输入奖励金额', trigger: 'blur' }],
                standard2StartDays: [{ required: true, message: '请输入起始天数', trigger: 'blur' }],
                standard2TargetAmount: [{ required: true, message: '请输入目标金额', trigger: 'blur' }],
                standard3EndDays: [{ required: true, message: '请输入结束天数', trigger: 'blur' }],
                standard3RewardAmount: [{ required: true, message: '请输入奖励金额', trigger: 'blur' }],
                standard3StartDays: [{ required: true, message: '请输入起始天数', trigger: 'blur' }],
                standard3TargetAmount: [{ required: true, message: '请输入目标金额', trigger: 'blur' }],
                flowStartDays: [{ validator: checkFlowStartDays, trigger: 'blur' }],
                flow2StartDays: [{ validator: checkFlow2StartDays, trigger: 'blur' }],
            },
            active: '1'
        }
    },
    created() {
        let activityNo = this.$route.query.activityNo
        PolicyApi.activityInfo.detail(activityNo)
            .then(res => {
                if (res.success) {
                    this.activityInfoDo = res.data
                    if (this.activityInfoDo.flowCost3 == null) {
                        this.active = '2'
                    }
                }
            })
        PolicyApi.policyInfo.getPolicyList()
            .then(res => {
                if (res.success) {
                    this.policyList = res.data
                }
            })
    },
    methods: {
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true
                    if (this.active == '2') {
                        this.activityInfoDo.flowCost3 = null
                    } else {
                        this.activityInfoDo.flowStartDays = null
                        this.activityInfoDo.flowEndDays = null
                        this.activityInfoDo.flow2StartDays = null
                        this.activityInfoDo.flow2EndDays = null
                    }
                    let params = this.activityInfoDo
                    console.log(this.activityInfoDo)
                    PolicyApi.activityInfo.edit(this.$route.query.activityNo, params)
                        .then(res => {
                            this.loading = false
                            if (res.success) {
                                this.$router.push({
                                    name: 'acticityPolicyDetail',
                                    query: {
                                        activityNo: this.$route.query.activityNo
                                    }
                                })
                            }
                        })
                } else {
                    return false;
                }
            });

        },
        tabClick(e) {
            this.activityInfoDo.flowCost = null
            this.activityInfoDo.flowCost2 = null
            this.activityInfoDo.flowCost3 = null
            this.activityInfoDo.flowStartDays = null
            this.activityInfoDo.flowEndDays = null
            this.activityInfoDo.flow2StartDays = null
            this.activityInfoDo.flow2EndDays = null
        },
        blurChange() {
            this.activityInfoDo.flow2StartDays = Number(this.activityInfoDo.flowEndDays) + 1
        }
    }
}
</script>
<style lang="scss">
.inline-input {
    display: inline-block;
    width: 60px;
}
</style>